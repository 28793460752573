import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <circle
      cx={128}
      cy={108}
      r={87}
      stroke="var(--stroke-color)"
      strokeWidth="var(--stroke-width)"
      vectorEffect="non-scaling-stroke"
    />
    <path
      stroke="var(--stroke-color)"
      strokeWidth="var(--stroke-width)"
      vectorEffect="non-scaling-stroke"
      d="M82.418 125c-6.826 0-12.97 4.322-14.86 10.882-9.048 31.402-7.074 53.933-.554 85.635 1.993 9.689 12.354 15.094 21.52 11.374l33.458-13.583a15.996 15.996 0 0 1 12.036 0l33.459 13.583c9.165 3.72 19.526-1.685 21.519-11.374 6.52-31.702 8.494-54.233-.554-85.635-1.889-6.56-8.034-10.882-14.86-10.882H82.418Z"
    />
    <path
      fill="#FC4848"
      d="M82.418 125c-6.826 0-12.97 4.322-14.86 10.882-9.048 31.402-7.074 53.933-.554 85.635 1.993 9.689 12.354 15.094 21.52 11.374l33.458-13.583a15.996 15.996 0 0 1 12.036 0l33.459 13.583c9.165 3.72 19.526-1.685 21.519-11.374 6.52-31.702 8.494-54.233-.554-85.635-1.889-6.56-8.034-10.882-14.86-10.882H82.418Z"
    />
    <circle cx={128} cy={108} r={87} fill="#FEE333" />
    <circle cx={128} cy={108} r={60} fill="#FEC701" />
    <path
      fill="#FFF7AC"
      fillRule="evenodd"
      d="M201.608 61.601 81.601 181.608a87.451 87.451 0 0 1-28.031-28.537L173.07 33.57A87.451 87.451 0 0 1 201.608 61.6Z"
      clipRule="evenodd"
      opacity={0.4}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);

export default Memo;
